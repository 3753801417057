.login { width: 100vw; height: 100vh; display: flex; justify-content: center; background-color: #FDFDFD;
    & .contentLogin{ display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;
        & .panel{ min-width: 360px; padding: 68.53px 30px 79px; border-radius: 10px; box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08); 
            & .acessaForm{ margin-top: 60px;
                & h2{ margin-bottom: 60px; }
                & div{ display: flex; flex-direction: column;
                    & label{ color: #888888; font-size: 16px; font-weight: bold; text-align: left; }
                    & input{ border: none; border: 1px solid #838FA6; font-size: 20px; height: 46.94px; border-radius: 5px;
                        &::placeholder{ color: var(--cinzaMedio) }
                        &:last-child{ margin-top: 28.16px; }
                    }
                }
                & .btn.entrar{ height: 50px; margin-top: 33.41px; font-size: 18px; font-weight: bold; text-transform: uppercase;
                    color: var(--preto); background-color: var(--corPadrao); width: 100%;
                }
            }
        }
    }
}

.dashboard{
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .rowCenter{ flex-wrap:wrap;
        & .panel{ max-width: 480px; margin-right: 10px; width:calc(50% - 10px); -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box;
            & p{ font-weight: normal; font-size: 18px; letter-spacing: -0.33px; color: var(--cinzaPreto); margin-top: 5px; margin-bottom: 9px; }
            & h3{ font-weight: bold; font-size: 30px; letter-spacing: -0.33px; margin-top: 0; margin-bottom: 0; 
                &.cinza{ color: var(--cinza); }
            }
            &:last-child{ margin-right: unset; }
        }
    }
    & .panel{
        & .header{ display: flex; align-items: center;
            & .filtros{ margin-bottom: 32px;
                & button{ border: 1px solid var(--preto); color: var(--preto); font-size: 15px; width: 120px; border-radius: 20px; margin-right: 20px; }
                & button.active, & button:hover{ background-color: var(--preto); color: var(--branco); }
            }
            & .subtitle{ margin-left: auto; text-align: right; display: flex; align-items: center;
                span{ width: 15px; height: 14px; margin-right: 3px; 
                    &.green{ background-color: var(--verdeExoss); }
                    &.red{ background-color: var(--vermelho); }
                }
                p{ font-weight: 500; font-size: 14px; margin-right: 13px;  margin-top: 0; margin-bottom: 0; }
            }
        }
    }
    & .multiGraph{ display: flex; 
        & .panel{ display: flex; flex-direction: column; width: 50vw;
            & p.titulo{ font-size: 16px; font-weight: bold; color: var(--azulEscuro); }
            & canvas{ margin-top: auto; }
        }
        & .panel.mr{ margin-right: 60px; }
    }
}

.digitalAccounts{
    p.totalResults{ font-weight: 500; font-size: 16px; color: var(--cinzaEscuro); margin-top: 40px; }
    & .headerBtn button{ color: var(--corPadrao); border-color: var(--corPadrao); }
}

.transfer, .cardStatement{
    & .info{ margin-right: 31px;
        & p{ font-size: 14px; letter-spacing: -0.33px; margin-bottom: 7px; color: var(--cinzaEscuro); }
        & h3{ font-weight: 500; font-size: 16px; letter-spacing: -0.33px; margin-top: 0; color: var(--cinzaEscuro);  }
    }
    & .multiInput button{ border: 1px solid var(--corPadrao); width: 118px; height: 46px; margin-top: -6px; font-weight: 500; font-size: 16px; color: var(--corPadrao); }
    & .btnAction{ width: 235px; background-color: var(--corPadrao); text-transform: uppercase; float: right; }
}

.cardStatement{
    & .headerBtn button{ border-color: var(--corPadrao); color: var(--corPadrao); }
}