@media (min-width: 425px) and (max-width: 1024px){
    .dashboard .panel .header .btn{ margin-bottom: 10px; }
    .dashboard .multiGraph{ flex-direction: column; 
        & .panel{ width: unset; margin-right: 0 !important; }
    }
}

@media (max-width: 570px){
    .sideMenuBar{ display: none; }
    .body{ margin-left: 0; }
    .dashboard {
        & .panel .header{ flex-direction: column; text-align: center;
            & .btn{ margin-bottom: 10px; }
        }
        & .faturamento{ margin-left: unset !important; }
        & .multiGraph{ flex-direction: column; 
            & .panel{ width: unset; margin-right: 0 !important; }
        }
    }
    .footer{ padding: 2.5px 0; }
}

@media (min-width: 730px) {
    .linksBar { display: flex !important; }
}

@media (min-width: 768px) {
    .container { width: 750px; }
}

@media (min-width: 992px) {
    .container { width: 970px; }
}

@media (min-width: 1200px) {
    .container { width: 1200px; }
}

@media (max-height: 720px){
    .login .contentLogin{
        & .logo{ margin-top: -63px; }
        & .acessaForm {
            & h2{ margin-top: -20px; margin-bottom: 30px; }
            & .btn.entrar{ margin-top: 15px; }
        }
    }
}

@media (max-width: 855px){
    .multiInput .inputDefault{ margin-right: 0 !important; }
    .panelBody .inputDefault{ max-width: 100% !important; }
}